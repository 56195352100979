html {
  --scrollbarBG: #f5f5f5;
  --thumbBG: #90a4ae;
}
body::-webkit-scrollbar {
  width: 0px;
}
@font-face {
  font-family: "Atlanta";
  src: url(./assets/fonts/AntarcticaDemoBold.ttf) format("truetype");
}
@font-face {
  font-family: "Atlanta-headline";
  src: url(./assets/fonts/AntarcticanHeadline-Black.otf) format("truetype");
}
body {
  scrollbar-width: thin;
  scrollbar-color: var(--thumbBG) var(--scrollbarBG);
}
body::-webkit-scrollbar-track {
  background: var(--scrollbarBG);
}
body::-webkit-scrollbar-thumb {
  background-color: var(--thumbBG);
  border-radius: 6px;
  border: 3px solid var(--scrollbarBG);
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: rgb(255, 255, 255);

  cursor: url("assets/mouse.png"), auto;

  /* animation-name: example;
  animation-duration: 2s; */
  /* overflow: hidden; */
  /* background-image: url("assets/web_bg.jpeg"); */
  /* background-repeat: no-repeat;
  background-size: cover;  */
}
@keyframes example {
  0% {
    background-color: black;
    height: 100vh;
    width: 100vw;
    background-repeat: no-repeat;
    background-size: cover;
  }
  100% {
    height: 100vh;
    width: 100vw;
    background-image: url("assets/web_bg.jpeg");
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
@tailwind base;
@tailwind components;
@tailwind utilities;
@layer utilities {
  /* Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }

  .no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
}
@font-face {
  font-family: "Aveny T";
  src: url("./assets/fonts/avenyt.otf");
}
@font-face {
  font-family: "Papyrus";
  src: url("./assets/fonts/papyrus.TTF");
}
@font-face {
  font-family: "Alpha Echo";
  src: url("./assets/fonts/alpha_echo.ttf");
}
